const initialState = {
  isAuthenticated: false,
  sessionInfo: null,
  logginIn: false
}
const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGE':
      return {
        ...state,
        sessionInfo: action.auth,
        isAuthenticated: action.auth !== null
      }
    case 'USER_LOGIN':
      return {
        ...state,
        loginInProgress: true
      }
    case 'USER_LOGIN_SUCCESS':
      return {
        ...state,
        loginInProgress: false
      }
    case 'USER_LOGIN_ERROR':
      return {
        ...state,
        loginInProgress: false,
        sessionInfo: null,
        isAuthenticated: false
      }
    default:
      return state
  }
}

export default auth