import {h} from 'preact';
import style from './style.css';


const InputField = (props) => {
	let inputId = props.inputId;
	return (<div class={`${style.inputfieldContainer  } inputfield`}>
	<label for={inputId}>{props.label}</label>
	<input id={inputId} onChnge={props.onChange} value={props.value} class={style.inputfield} {...props} />
	</div>);
}

export default InputField;