// import { combineReducers } from 'redux'
// import app from './app';
// import search from './search';

// const  appStore = combineReducers({
// 	app,
// 	search
// })

// export default appStore;

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import { reactReduxFirebase } from 'react-redux-firebase';
import webapiMiddleware from 'src/redux/middleware/webapimiddleware';
import app from './app';
import auth from './auth';
import search from './search';
import _firebase from './firebase';

// let  firebaseConfig
// if (process.env.REACT_APP_ENV === 'development') {
//   firebaseConfig = configProduction
// }else{
//   firebaseConfig = configStaging
// }

//const reduxFirebaseConfig = { userProfile: 'users' };
//console.log(process.env.REACT_APP_ENV);
const rootReducer = combineReducers({
	app,
	auth,
	search,
	_firebase
});

// Replace with your Firebase config

// export default function configureStore(initialState, history) {
const store = (function configureStore() {
	const createStoreWithMiddleware = compose(
		applyMiddleware(thunkMiddleware, webapiMiddleware),
		// reactReduxFirebase(firebaseConfig, reduxFirebaseConfig),
		// Redux Devtools
		typeof window === 'object' && typeof window.devToolsExtension !== 'undefined'
			? window.devToolsExtension()
			: (f) => f
	)(createStore);
	const store = createStoreWithMiddleware(rootReducer);

	return store;
})();
export default store;