const sv = {
	app:{
		login:{
			username:'Användarnamn',
			password: 'Lösenord',	
			logout:"Logga ut",
			register:"Registrera",
			remember_me: "Kom ihåg mig!",
			forgot_password: "Glömt lösenordet",
			orLogin:'Eller logga in med'
		},
		menu:{
			home:"Hem",
			buy:"Köpa",
			sell:"Sälja",
			login: "Logga in",
			logout:"Logga ut",
			register:"Registrera nytt konto",
			my_page:'Min sida'
		},
	},
	createAd:{
		start_text:'Var vill du börja?',
		create_your_ad:'Skapa din annons',
	}
}

export default sv;