import {h, Component} from 'preact'
import Header from './header';
import {   BrowserRouter as Router,
  Switch,
  Route } from 'react-router-dom';
import { connect } from 'react-redux'
import Home from '../routes/home';
import Sell from '../routes/sell';
import PropertyInfo from 'src/routes/propertyinfo';
import LoginDialog from 'src/components/loginDialog'
import { toggleLoginDialog } from  'src/redux/actions/app';

class Base extends Component {
	
	/** Gets fired when the route changes.
	 *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
	 *	@param {string} event.url	The newly routed URL
	 */
	handleRoute = e => {
		this.currentUrl = e.url;
	};
	toggleLogin = () => {
		this.props.dispatch(toggleLoginDialog());
	}
	render() {
		return (
			<div id="app">
				{this.props.showLogin ? <LoginDialog translation={this.props.translation} toggleLogin={this.toggleLogin} /> : null}
				
				<Router >
					<Header />
					<Switch>
						<Route path="/sell">
							<Sell />
						</Route>
						<Route path="/bostad">
							<PropertyInfo />
						</Route>
						<Route path="/">
							<Home />
						</Route>
					</Switch>
				</Router>
			</div>)
	}
}
const mapStateToProps = (state) => {
	return {
		showMenu: state.app.showMenu,
		showLogin: state.app.showLogin,
		isAuthenticated: state.auth.isAuthenticated,
		browserLanguage: state.app.browserLanguage,
		translation: state.app.translation
	}
}
export default connect(mapStateToProps)(Base)