import initialState from './initialState';

function search(state = initialState, action ){
	switch (action.type){
		case "SET_FILTER":
			return {
				...state,
				filter: action.filter
			}
		default:
			return state;
	}
}
export default search;