import fbHandler from './firebashandler'

export const emailLogin = (email, password) => {
  if (!email || !password) return Promise.reject(new Error('Email and password required'))
  return fbHandler.signInWithEmailAndPassword(email, password)
}

export const emailUserRegistration = (email, password) => {
  if (!email || !password) return Promise.reject(new Error('Email and password required'))
  return fbHandler.createUserWithEmailAndPassword(email, password)
}
export const logout = () => {
  return fbHandler.signOut()
}

export const googleUserLogin = () => {
	return fbHandler.signInWithGoogle();
}

export const facebookUserLogin = () => {
	return fbHandler.signInWithFacebook();
}