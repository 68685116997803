/* Games are supposed to be used as arrays most of the time */
/* Store them with the slug as their key. */

const initialState = {
  isOnline:true,
}
const _firebase = (state = initialState, action) => {
  let splittedActionType = action.type.split('/')

  if (splittedActionType[0] !== 'firebase') return state
  if (!splittedActionType[1] && splittedActionType[2]) return state

  switch (splittedActionType[1]) {
    case 'GO_OFFLINE':
      return {
        isOnline: false
      }
    case 'LOADING':
      return {
        ...state,
        [splittedActionType[2]]:{...action.value}
      }
    case 'LOADED':
        return {
          ...state,
          [splittedActionType[2]]:{
            ...action.value,
            items: [...action.value.items]
          }
        }
    case 'CHILD_ADDED':
        return {
          ...state,
          [splittedActionType[2]]:{
            ...action.value,
            items: [...action.value.items]
          }
        }

    case 'CHILD_REMOVED':
    console.log('child removed')
    console.log(action)
      return {
        ...state,
        [splittedActionType[2]]:{
          ...action.value,
          items: [...action.value.items]
        }

      }

    case 'CHILD_CHANGED':
      return {
        ...state,
        [splittedActionType[2]]:{
          ...action.value,
          items: [...action.value.items]
        }
      }

    case 'CHILD_MOVED':
      return {
        ...state,
        [splittedActionType[2]]:{
          ...action.value,
          items: [...action.value.items]
        }
      }
    case 'ADD_CHILD':
      return {
        ...state,
        adding:{...state.adding, [action.key]: 1}
      }
    case 'UPDATE_CHILD':
      return {
        ...state,
        updating:{...state.updating, [action.key]: 1}
      }      
    case 'REMOVE_CHILD':
      return {
        ...state,
        removing:{...state.removing, [action.key]: 1}
      }            
    default:
      return state
  }
}

export default _firebase