import {h} from 'preact';
import { connect } from 'react-redux';
import style from './style.css';
import { logout } from  'src/firebase/auth';

const Login = (props) => {
	const doLogout = () => {
		logout();
	}
	return (
		<div class={`${style.login} login`} >
			<button 
				class="action" 
				onClick={props.isAuthenticated ? doLogout : props.toggleDialog}
			>
				{props.isAuthenticated ? props.translation.app.menu.logout : props.translation.app.menu.login}
			</button>
		</div>
	)
} 
export default connect()(Login);