const en = {
	app:{
		login:{
			username:'Username',
			password: 'Password',	
			logout:"Logout",
			register:"Register",
			remember_me: "Remember me!",
			forgot_password: "Forgot password",
			orLogin:'Or login with:'
		},
		menu:{
			home:"Home",
			buy:"Buy",
			sell:"Sell",
			login: "Login",
			logout:"Logout",
			register:"Register new account"

		}
	}
};
export default en;