import {
  emailLogin,
  googleUserLogin,
  facebookUserLogin,
  logout
} from 'src/firebase/auth'
import {hideLoginDialog} from './app';

export const userLogin = () => {
  return {
    type: 'USER_LOGIN',
  }
}

export const userLoginSuccess = () => {
  return {
    type: 'USER_LOGIN_SUCCESS',
  }
}

export const userLoginError = (error) => {
  return {
    type: 'USER_LOGIN_ERROR',
	error
  }
}

export const userLogout = () => {
	return {
		type: 'USER_LOGOUT'
	}
};

export const authStateChange = (auth) => {
  return {
    type: 'AUTH_STATE_CHANGE',
    auth
  }
}

export function authenticate(email, password) {
  return dispatch => {
    dispatch(userLogin(email, email))
    return emailLogin(email, password)
      .then(() => {
		  dispatch(userLoginSuccess())
		  dispatch(hideLoginDialog());
		  })
      .catch(err => {
        dispatch(userLoginError(err))
      })
  }
}

export function authenticateGoogle() {
  return dispatch => {
    dispatch(userLogin())
    return googleUserLogin()
      .then(() => {
		  dispatch(userLoginSuccess());
		  dispatch(hideLoginDialog());
	  })
      .catch(err => {
        dispatch(userLoginError(err))
		
      })
  }
}


export function authenticateFacebook() {
  return dispatch => {
    dispatch(userLogin())
    return facebookUserLogin()
      .then(() => {
		  dispatch(userLoginSuccess());
		  dispatch(hideLoginDialog());
		})
      .catch(err => {
        dispatch(userLoginError(err))
      })
  }
}


export function logoutUser() {
  return dispatch => {
    dispatch(userLogout())
    return logout()
      .catch(err => {
        console.log(err)
      })
  }
}