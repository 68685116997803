import { h } from 'preact';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import style from './style.css';
import Login from 'src/components/login';
import { toggleLoginDialog } from  'src/redux/actions/app';

const Header = (props) => {
	const menu_language = props.translation.app.menu
	const toggleMenu = () => {
		props.dispatch({type:'TOGGLE_HEADER_NAV'})
	}


	return(
	<header class={style.header}>
		<Link activeClassName={style.active} to="/"><h1>Bjuru</h1></Link>
		<nav class={props.showMenu ? style.visible : ''}>
			<Link activeClassName={style.active} to="/">{menu_language.home}</Link>
			<Link activeClassName={style.active} to="/köpa">{menu_language.buy}</Link>
			<Link activeClassName={style.active} to="/sell">{menu_language.sell}</Link>
			{props.isAuthenticated ? <Link activeClassName={style.active} href="/my-page">{menu_language.my_page}</Link> : null }
			<Login 
				translation={props.translation} 
				isAuthenticated={props.isAuthenticated} 
				toggleDialog={ () => {props.dispatch(toggleLoginDialog())}}
			/>	
		</nav>
		<div class={style.hamburger} onClick={toggleMenu}>☰</div>
	</header>
	);
}
const mapStateToProps = (state) => {
	return {
		showMenu: state.app.showMenu,
		isAuthenticated: state.auth.isAuthenticated,
		browserLanguage: state.app.browserLanguage,
		translation: state.app.translation
	}
}
export default connect(mapStateToProps)(Header)