import { h } from 'preact';
import { Provider } from 'react-redux';
//import store from '../redux/store';
import firebaseHandler from 'src/firebase/firebashandler'
import {appInit} from 'src/redux/actions/app';
import store from 'src/redux/reducers'
import Base from './base';

		store.dispatch(appInit(store))

		firebaseHandler.init(store, true);

const App = () => (
			<Provider store={store} >
				<Base />
			</Provider>
		)
export default App;
