import sv from 'src/translations/sv';
import en from 'src/translations/en';

const languages = {
	sv,
	en,
	
}
function getBrowserLanguage(){
	if (typeof window !== "undefined"){
		return navigator.language.slice(0,2) || 'en';
	}
	return 'en';
}
const setInitialLanguage = () => {
	if (typeof window !== "undefined"){
		return languages[getBrowserLanguage()];
	}
	return languages.en;
}
const initialState = {
  isMobile: false,
  showMenu: false,
  showLogin: false,
  browserLanguage: getBrowserLanguage(),
  translation: setInitialLanguage(),
}

export default initialState;