import {h} from 'preact';
import { useState } from 'preact/hooks';
import { Link } from 'preact-router/match';
import style from './style.css';
import InputField from 'src/components/inputs/inputfield';
import Checkbox from 'src/components/inputs/checkbox';
import Overlay from 'src/components/overlay';
import { emailLogin, emailUserRegistration,  googleUserLogin, facebookUserLogin } from  'src/firebase/auth';

const LoginDialog = (props) => {
	const menu_language = props.translation.app.menu
	const [rememberMe, setRememberMe] = useState(false);
	const [username, setUsername] = useState();
	const [password, setPassword] = useState();
	
	const toggleChecked = () => setRememberMe(!rememberMe);

	const doLogin = () => emailLogin(username, password);
	
	const doRegister = () => emailUserRegistration('asdfasdfa@asdf.se', 'asdfasdfasfd');

	const updateUsername = (e) => setUsername(e.target.value);		

	const updatePassword = (e) => setPassword(e.target.value);

return (
	<Overlay onClick={props.toggleLogin}>
	<div class={`login-dialog ${style.loginDialog}`}>
		<div class={style.closeDialog} onClick={props.toggleLogin}>×</div>
		<div>
			<InputField label={props.translation.app.login.username} onChange={updateUsername} value={username} placeholder={props.translation.app.login.username} />
			<InputField label={props.translation.app.login.password} onChange={updatePassword} value={password} type="password" placeholder={props.translation.app.login.password} />
			<div class={style.loginOptions}>
				<Checkbox label={props.translation.app.login.remember_me} onClick={toggleChecked} checked={rememberMe} />
				<Link activeClassName={style.active} href="/glomt-losenord">{props.translation.app.login.forgot_password}</Link>
			</div>

			<button class="action" onClick={doLogin}>{props.translation.app.menu.login}</button>
			<button onClick={doRegister} class="actionInverted">{menu_language.register}</button>
			<h3><span>{props.translation.app.login.orLogin}</span></h3>

			
			<div class={style.socialLogin}>
				<button onClick={googleUserLogin} class={style.googleLogin}>Google</button>
				<button onClick={facebookUserLogin} class={style.facebookLogin}>Facebook</button>
			</div>
		</div>
	</div>
	</Overlay>
	)
			
} 
export default LoginDialog;