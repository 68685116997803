import initialState from './initialState';

export default function app(state = initialState, action ){
	switch (action.type){
		case "TOGGLE_HEADER_NAV":
			return {
				...state,
				showMenu: !state.showMenu
			}
		case "TOGGLE_LOGIN_DIALOG":
			return {
				...state,
				showLogin: action.payload != null && action.payload != undefined ? action.payload : !state.showLogin
			}
		default:
			return state;
	}
}
