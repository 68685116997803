import {h} from 'preact';
import style from './style.css';

const Overlay = (props) => {
	const onClick = e => {
		e.preventDefault();
		if(e.target === e.currentTarget) {
			props.onClick();
		}
	}
	return (
		<div class={style.overlay} onClick={onClick}>
			{props.children}
		</div>
	)
}
export default Overlay