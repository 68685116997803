import { h } from 'preact';
import style from './style.css';
import { useState } from 'preact/hooks';

const Checkbox = (props) => {
	const [isChecked, setChecked] = useState(props.checked)
	const toggleChecked = (e) => {
		e.preventDefault();
		setChecked(!isChecked)
		if (props.onClick) props.onClick();
	}
	return (
		<div class={style.checkbox} onClick={toggleChecked}>
			<input type="checkbox" checked={isChecked} />
			<label class={style.csslabel} for="">{props.label}</label>
		</div>
	)
}

export default Checkbox