export const startInit = () => {
  return {
    type:'APP_START_INIT',
  }
}

export const endInit = () => {
  return {
    type:'APP_END_INIT'
  }
}

export function appInit() {
  return dispatch => {
    dispatch(startInit());
	dispatch(endInit());
    // return EmSession.init(store)
    //     .then( response => dispatch(endInit()) )
    //     .catch( response => dispatch(endInit()) )
  }
}

export const toggleLoginDialog = () => {
  return {
    type:'TOGGLE_LOGIN_DIALOG'
  }
}
export const hideLoginDialog = () => {
  return {
    type:'TOGGLE_LOGIN_DIALOG',
	payload:false,
  }
}